// Imports => Constants
import { ENDPOINTS } from '@constants';

export class PermissionsAPI {
  constructor(Client, config) {
    this.Client = Client;
    this.config = config;
  }

  get_all_permissions = (params) => {
    return this.Client.get(ENDPOINTS.PERMISSIONS.LIST, { params }).then(
      (response) => response.data
    );
  };

  get_all_roles = (params) => {
    return this.Client.get(ENDPOINTS.PERMISSIONS.ROLES, { params }).then(
      (response) => response.data
    );
  };

  get_permissions_per_role = (role, params) => {
    return this.Client.get(ENDPOINTS.PERMISSIONS.ROLE_AND_PERMISSIONS(role), {
      params,
    }).then((response) => response.data);
  };

  assign_permission_to_role = (data) => {
    return this.Client.patch(
      ENDPOINTS.PERMISSIONS.ASSIGN_PERMISSION,
      data
    ).then((response) => response.data);
  };

  remove_permission_from_role = (data) => {
    return this.Client.patch(
      ENDPOINTS.PERMISSIONS.REMOVE_PERMISSION,
      data
    ).then((response) => response.data);
  };
}

export default PermissionsAPI;

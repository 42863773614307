// Imports => Vendor
import axios from 'axios';

// Imports => Interceptors
import { AcTokenRefresher } from './interceptors.api';

// Imports => API
import AuthAPI from '@api/auth.api';
import CompaniesAPI from '@api/companies.api';
import ConfigurationsAPI from '@api/configurations.api';
import ContractsAPI from '@api/contracts.api';
import ControlUnitsAPI from '@api/control-units.api';
import ControlUnitTypesAPI from '@api/control-unit-types.api';
import EquipmentAPI from '@api/equipment.api';
import ErpAPI from '@api/erp.api';
import HammersAPI from '@api/hammers.api';
import HammerTypesAPI from '@api/hammer-types.api';
import JeanLutzAPI from '@api/jean-lutz.api';
import PileListsAPI from '@api/pilelist.api';
import PowerpacksAPI from '@api/powerpacks.api';
import PowerpackTypesAPI from '@api/powerpack-types.api';
import ProfileAPI from '@api/profile.api';
import ProjectsAPI from '@api/projects.api';
import ReportsAPI from '@api/reports.api';
import UsersAPI from '@api/users.api';
import TransfersAPI from '@api/transfers.api';
import { MyCompanyAPI } from './my-company.api';
import { AlertsAPI } from './alerts.api';
import PermissionsAPI from './permissions.api';

/*
const onUploadProgress = (event) => {
  console.group('[Axios] => fn.onUploadProgress');
  console.log('Event: ', event);
  console.groupEnd();
};

const onDownloadProgress = (event) => {
  console.group('[Axios] => fn.onDownloadProgress');
  console.log('Event: ', event);
  console.groupEnd();
};
*/
let _timeOut = null;
let _errorTokens = [];

const unauthenticatedState = (state) => {
  const unauthenticatedEvent = new CustomEvent('unAuthenticate');
  window.dispatchEvent(unauthenticatedEvent);
};

const cancelRequests = () => {
  const collection = _errorTokens;
  const len = collection.length;
  let n = 0;

  for (n; n < len; n++) {
    const instance = collection[n];
    if (instance && instance.cancel) instance.cancel();
  }

  _errorTokens = [];
};

const addInterceptors = (requestClient) => {
  requestClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        clearTimeout(_timeOut);

        const cancelRequestsEvent = new CustomEvent('cancelRequests');
        window.dispatchEvent(cancelRequestsEvent);

        _timeOut = setTimeout(() => unauthenticatedState(false), 500);
      }
      return Promise.reject(error);
    }
  );

  requestClient.interceptors.request.use(async (config) => {
    if (navigator.onLine)
      await AcTokenRefresher(requestClient, unauthenticatedState);

    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    _errorTokens.push(source);

    return config;
  });
};

export default (config) => {
  const NoAuthClient = axios.create({
    ...config.api_no_auth,
  });

  const Client = axios.create({
    ...config.api,
  });
  addInterceptors(Client);

  const PlainClient = axios.create({
    ...config.plain,
  });
  addInterceptors(PlainClient);

  const DownloadClient = axios.create({
    ...config.download,
  });
  addInterceptors(DownloadClient);

  const UploadClient = axios.create({
    ...config.upload,
  });
  addInterceptors(UploadClient);

  window.addEventListener('cancelRequests', cancelRequests, false);

  const auth = new AuthAPI(Client, NoAuthClient, config);
  const companies = new CompaniesAPI(Client, config);
  const configurations = new ConfigurationsAPI(Client, DownloadClient, config);
  const contracts = new ContractsAPI(Client, config);
  const control_units = new ControlUnitsAPI(Client, PlainClient, config);
  const control_unit_types = new ControlUnitTypesAPI(
    Client,
    UploadClient,
    config
  );
  const equipment = new EquipmentAPI(Client, config);
  const hammers = new HammersAPI(Client, config);
  const hammer_types = new HammerTypesAPI(Client, UploadClient, config);
  const jean_lutz = new JeanLutzAPI(Client, config);
  const myCompany = new MyCompanyAPI(Client, UploadClient);
  const permissions = new PermissionsAPI(Client, config);
  const pilelist = new PileListsAPI(
    Client,
    UploadClient,
    DownloadClient,
    config
  );
  const powerpacks = new PowerpacksAPI(Client, config);
  const powerpack_types = new PowerpackTypesAPI(Client, UploadClient, config);
  const profile = new ProfileAPI(Client, config);
  const projects = new ProjectsAPI(
    Client,
    DownloadClient,
    UploadClient,
    config
  );
  const reports = new ReportsAPI(Client, DownloadClient, config);
  const users = new UsersAPI(Client, config);
  const erp = new ErpAPI(Client, config);
  const alerts = new AlertsAPI(Client, config);
  const transfers = new TransfersAPI(
    Client,
    UploadClient,
    DownloadClient,
    config
  );

  return {
    auth,
    companies,
    configurations,
    contracts,
    control_units,
    control_unit_types,
    equipment,
    hammers,
    hammer_types,
    jean_lutz,
    myCompany,
    permissions,
    pilelist,
    powerpacks,
    powerpack_types,
    profile,
    projects,
    reports,
    transfers,
    users,
    erp,
    alerts,
  };
};
